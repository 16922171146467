var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-card',{attrs:{"body-class":"p-0"}},[_c('b-table',{attrs:{"fields":_vm.columns,"items":_vm.tableRows,"show-empty":""},scopedSlots:_vm._u([{key:"head()",fn:function(data){return [_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(data.label))])]}},{key:"cell(startedAt)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.timeWithTimeZone(data.item.startedAt, _vm.timeZone, _vm.COMMON.DATE_TIME_FORMAT))+" ")]}},{key:"cell(endedAt)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.timeWithTimeZone(data.item.endedAt, _vm.timeZone, _vm.COMMON.DATE_TIME_FORMAT))+" ")]}},{key:"cell(duration)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.CONVERT_HM(data.item.duration))+" ")]}},{key:"cell(action)",fn:function(data){return [_c('b-button',{staticClass:"btn-icon mr-2",attrs:{"variant":"outline-success badge-glow"},on:{"click":function($event){_vm.manualTimeToAccept = data.item}}},[_c('feather-icon',{attrs:{"icon":"CheckIcon"}})],1),_c('b-button',{staticClass:"btn-icon",attrs:{"variant":"outline-danger badge-glow"},on:{"click":function($event){_vm.manualTimeToReject = data.item}}},[_c('feather-icon',{attrs:{"icon":"XIcon"}})],1)]}},{key:"empty",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('reports.no-requested-time'))+" ")]},proxy:true}])})],1),_c('confirm-modal',{attrs:{"delete-params":{
      title: _vm.$t('Confirm'),
      body: _vm.$t('reports.accept-confirmation')
    },"variant":"success","visible":!!_vm.manualTimeToAccept,"processing":_vm.isProcessing},on:{"close":function($event){_vm.manualTimeToAccept = null},"onConfirm":_vm.acceptManualTime}}),_c('confirm-modal',{attrs:{"delete-params":{
      title: _vm.$t('Confirm'),
      body: _vm.$t('reports.reject-confirmation')
    },"processing":_vm.isProcessing,"variant":"danger","visible":!!_vm.manualTimeToReject},on:{"close":function($event){_vm.manualTimeToReject = null},"onConfirm":_vm.rejectManualTime}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }